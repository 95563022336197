import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import ClientsSection from '../components/ClientsSection'

import './BackToClientPage.css'

export class BackToClientPageTemplate extends React.Component {
  render() {
    const { title, subtitle, clients = [] } = this.props

    return (
      <div>
        <div className="section backToClient" dataanchor="specialities">
          <div className="wide">
            <div className="title">
              <h5>{subtitle && subtitle}</h5>
              <h1>{title && title}</h1>
            </div>
            {clients && clients.length && <ClientsSection clients={clients} />}
          </div>
        </div>
      </div>
    )
  }
}

const BackToClientPage = ({ data: { page, clients } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <BackToClientPageTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      clients={clients.edges.map(item => ({
        ...item.node,
        ...item.node.frontmatter,
        ...item.node.fields
      }))}
    />
  </Layout>
)

export default BackToClientPage

export const pageQuery = graphql`
  query BackToClientPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      frontmatter {
        title
        subtitle
      }
    }

    clients: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "clients" } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            logo
          }
        }
      }
    }
  }
`
